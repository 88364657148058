import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  private _alphaNum: string = 'AB!!CDEF=GHI&JKLMNOPQR#STUVW@XYZ012345*6789';

  /** The encryption IV string generated through known indices of @_alphaNum */
  private _x() {
    return [12, 39, 30, 39, 22, 32, 38, 13, 1, 9, 16, 19, 24, 4, 39, 33]
      .map((v) => this._alphaNum.charAt(v))
      .join('');
  }

  /** The encryption Key string generated through known indices of @_alphaNum */
  private _y() {
    return [28, 25, 10, 16, 35, 31, 12, 8, 21, 11, 12, 26, 36, 18, 16, 2]
      .map((v) => this._alphaNum.charAt(v))
      .join('');
  }

  encryptData(data: string): string {
    try {
      const encrypted = CryptoJS.AES.encrypt(
        data,
        CryptoJS.enc.Utf8.parse(this._y()),
        {
          iv: CryptoJS.enc.Utf8.parse(this._x()),
        }
      );

      // Return the encrypted data as a string
      return encrypted.toString();
    } catch (error) {
      console.error('Encryption error:', error);
      return data; // Return original data if encryption fails
    }
  }

  decryptData(data: string): string {
    try {
      // Decrypt the data
      const decrypted = CryptoJS.AES.decrypt(
        data,
        CryptoJS.enc.Utf8.parse(this._y()),
        {
          iv: CryptoJS.enc.Utf8.parse(this._x()),
        }
      );

      // Convert the decrypted data to a UTF-8 string
      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error('Decryption error:', error);
      return data; // Return original data if decryption fails
    }
  }
}
