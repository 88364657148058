import { Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { UserIdleService } from 'angular-user-idle';
import { IdleTimeoutService } from './core/services/idle-timeout.service';
import { isPlatformServer } from '@angular/common';
import Store from './shared/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'device-management';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date ;
  constructor(
    private idleTimeoutSVC: IdleTimeoutService, 
    @Inject(PLATFORM_ID) private platformId:any,
    private pgRoute:Router,
    //  private userIdle: UserIdleService, 
     ){

    //   this.userIdle.startWatching();
    //  // Start watching when user idle is starting.
    // this.userIdle.onTimerStart().subscribe(
    //   count =>{
    //     this.userIdle.resetTimer();
    //     this.pgRoute.navigate([`/login`]);
    //   }
    // );
    // // Start watch when time is up.
    // this.userIdle.onTimeout().subscribe(() =>
    //  );


    if (!isPlatformServer(this.platformId)) {
      this.idleTimeoutSVC.timeOut.subscribe(_ => {
        // new Store().clearAll(),
        this.pgRoute.navigate([`/login`]);
      });
      this.idleTimeoutSVC.startCountDown(10);
    }
  }
 
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(_event: KeyboardEvent) {
    this.idleTimeoutSVC.restart();
  }
 
  @HostListener('document:click', ['$event'])
  documentClick(e:any) {
    this.idleTimeoutSVC.restart();

  }
  @HostListener("window:scroll", ['$event'])
  onWindowScroll() {
    this.idleTimeoutSVC.restart();
  }
}
