import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, finalize, tap, map } from 'rxjs/operators';
import Store, { AppKey } from '../../store';
import { User } from 'src/app/models/User';
import { ErrorResponse } from 'src/app/models/ErrorResponse';
import { EncryptionService } from 'src/app/core/services/encryption.service';

@Injectable()
export class AppInterceptorService implements HttpInterceptor {
  store = new Store();

  constructor(private encryptionService: EncryptionService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const started = Date.now();
    const user = this.store.get(AppKey.USER);
    const defaultToken = 'VBAE6MDAiLCJTdGF0ZSI6DP0==';

    const req: HttpRequest<string> = request.clone({
      headers: request.headers
        .set('Content-Type', 'text/plain')
        .set(
          'Authorization',
          user && (user as User).Token ? (user as User).Token : ''
        ),
      responseType: 'text',
    });

    return next.handle(req).pipe(
      // Transform successful responses (decrypt if necessary)
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Handle response - if it's an encrypted string, decrypt it
          const body = event.body;
          if (body && typeof body === 'string') {
            try {
              const decryptedData = this.encryptionService.decryptData(body);
              const parsedData = JSON.parse(decryptedData);
              return event.clone({ body: parsedData });
            } catch (error) {
              console.error('Error decrypting response in interceptor:', error);
              // Return original response if decryption fails
              return event;
            }
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let errorMsg = '';
        if (error && typeof error.error === 'string') {
          try {
            const decryptedData = this.encryptionService.decryptData(
              error.error
            );
            const parsedData = JSON.parse(decryptedData);
            if (parsedData instanceof ErrorEvent) {
              // Client-side error
              errorMsg = `Error: ${parsedData.message}`;
            } else {
              // Server-side error
              if (error.status === 0 && parsedData instanceof ProgressEvent) {
                if (!window.navigator.onLine)
                  errorMsg = 'Internet not available';
                else errorMsg = 'Server error';
              } else {
                if (parsedData) {
                  // Safely access nested properties
                  if (parsedData.ErrorMsg) {
                    errorMsg = parsedData.ErrorMsg;
                  } else if (
                    parsedData.errors &&
                    typeof parsedData.errors === 'object'
                  ) {
                    // Make sure parsedDatas exists and is an object before trying to get its keys
                    const errorKeys = Object.keys(parsedData.errors);
                    if (errorKeys.length > 0) {
                      errorMsg = parsedData.errors[errorKeys[0]].toString();
                    } else {
                      errorMsg = 'Unknown error occurred';
                    }
                  } else {
                    errorMsg = 'Error processing request';
                  }
                } else {
                  errorMsg = error.status + ' ' + error.statusText;
                }
              }
            }

            if (error.statusText == 'Unknown Error' && error.status !== 0) {
              errorMsg = 'Server is unreachable';
            }
          } catch (error) {
            console.error('Error decrypting response in interceptor:', error);
            // Return original response if decryption fails
            // return event;
          }
        }

        return throwError(errorMsg);
      })
    );
  }
}
