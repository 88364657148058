import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Role } from 'src/app/models/Role';
import { UIs } from 'src/app/models/UIs';
import { User } from 'src/app/models/User';
import { PermissionService } from 'src/app/shared/services/permission/permission.service';
import Store, { AppKey } from 'src/app/shared/store';

@Directive({
  selector: '[appIsRendered]',
})
export class IsRenderedDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permSvc: PermissionService
  ) {}
  @Input() set appIsRendered(ui: UIs) {
    this.isGranted(ui);
  }

  // @Input() appIsRendered: UIs;

  private isGranted(ui: UIs) {
    if (this.permSvc.hasPermission(ui)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
